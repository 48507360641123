var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      src: require("../images/christmas-tree.png"),
      alt: "christmas-tree",
      id: "christmas_tree"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }