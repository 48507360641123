<template>
    <div class="title">
        <div class="merry-christmas-text">Merry Christmas</div>
        <div class="happy-new-year"></div>
    </div>
</template>
<script>
export default {
    name: 'Title'
}
</script>

