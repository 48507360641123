var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("audio", {
      attrs: {
        id: "audio",
        src:
          "https://wangzilong-1256848957.cos.ap-guangzhou.myqcloud.com/Merry-Christmas.mp3",
        loop: "loop"
      }
    }),
    _vm._v(" "),
    _c("img", {
      class: { play: _vm.isPlayed, pause: !_vm.isPlayed },
      attrs: {
        src: require("../images/music_icon.png"),
        alt: "christmas-music",
        id: "christmas_music"
      },
      on: { click: _vm.click }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }