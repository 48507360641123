var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { staticClass: "parallax-container snow", attrs: { id: "container" } },
      [
        _c("Scene"),
        _vm._v(" "),
        _c("Timer"),
        _vm._v(" "),
        _c("Title"),
        _vm._v(" "),
        _c("Contact", { on: { watchChild: _vm.showDialog } }),
        _vm._v(" "),
        _c("Tree"),
        _vm._v(" "),
        _c("Gift", { on: { watchTurnChild: _vm.showTurnTable } }),
        _vm._v(" "),
        _c("Audio"),
        _vm._v(" "),
        _c("Dialog", { attrs: { isShow: _vm.isShow } }),
        _vm._v(" "),
        _c("TurnTable", {
          ref: "turnTable",
          attrs: { turnIsShow: _vm.turnIsShow }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }