var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      src: require("../images/gift1.png"),
      alt: "christmas-gift",
      id: "christmas_gift",
      "data-toggle": "modal",
      "data-target": "#turnTable_modal"
    },
    on: { click: _vm.showTurnTable }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }