var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      staticStyle: { display: "none" },
      attrs: { id: "contact_modal", tabindex: "-1", role: "dialog" }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-md-12 mail-container" }, [
              _c("div", { staticClass: "col-md-12 padding-none bg-color" }, [
                _c("div", { staticClass: "col-md-12 text-center" }, [
                  _vm._v("It's Christmas!")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-12 text-center" }, [
                  _vm._v("Tell me what gift you want!")
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "col-md-12 padding-none",
                    attrs: { onsubmit: "return false;" }
                  },
                  [
                    _c("div", { staticClass: "col-md-6 col-xs-12" }, [
                      _c("div", { staticClass: "col-md-12 padding-none" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name"
                            }
                          ],
                          staticClass: "mail-name",
                          attrs: {
                            type: "text",
                            id: "form_first_name",
                            placeholder: "姓名"
                          },
                          domProps: { value: _vm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12 padding-none" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          staticClass: "mail-email",
                          attrs: {
                            type: "text",
                            id: "form_valid_email",
                            placeholder: "邮箱"
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12 padding-none" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone"
                            }
                          ],
                          staticClass: "mail-phone",
                          attrs: {
                            type: "text",
                            id: "form_valid_phone",
                            placeholder: "手机号"
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12 padding-none" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gift,
                              expression: "gift"
                            }
                          ],
                          staticClass: "mail-gift",
                          attrs: { id: "form_gift", placeholder: "礼物" },
                          domProps: { value: _vm.gift },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.gift = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-xs-12 text-right" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../images/form-image.png"),
                            alt: "form-pattern-image"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn form-submit-button",
                            attrs: {
                              "data-dismiss": "modal",
                              type: "submit",
                              id: "submit_form_btn"
                            },
                            on: { click: _vm.send }
                          },
                          [_vm._v("Send")]
                        )
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-12 padding-none mail-container hidden" },
      [
        _c("div", {
          staticClass:
            "col-md-12 padding-none bg-color thank-you-msg text-large",
          attrs: { id: "form_success_msg" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }