var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      staticStyle: { display: "none" },
      attrs: { id: "turnTable_modal", tabindex: "-1", role: "dialog" }
    },
    [
      _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
        _c(
          "section",
          { staticClass: "gb-turntable", attrs: { id: "turntable" } },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "gb-turntable-btn",
                attrs: { href: "javascript:;" },
                on: { click: _vm.draw }
              },
              [_vm._v("抽奖")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close turnTable-close",
                attrs: { type: "button", "aria-label": "Close" },
                on: { click: _vm.click }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gb-turntable-container" }, [
      _c(
        "canvas",
        {
          staticClass: "gb-turntable-canvas",
          attrs: { width: "300", height: "300px" }
        },
        [_vm._v("抱歉！浏览器不支持。")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }