var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "margin-left": "-177.5px" },
      attrs: { id: "mail_pole" },
      on: { click: _vm.transform }
    },
    [
      _c("img", {
        staticClass: "img-responsive",
        attrs: {
          src: require("../images/pole.png"),
          alt: "mail-pole",
          "data-toggle": "modal",
          "data-target": "#contact_modal"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }