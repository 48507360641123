var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "merry-christmas-text" }, [
        _vm._v("Merry Christmas")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "happy-new-year" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }