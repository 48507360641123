<template>
    <ul id="christmas_scene" class="christmas-scene" >
        <li class="layer" data-depth="0.80"><div class="layer-5 layer-photo photo-zoom"></div></li>
        <li class="layer" data-depth="0.60"><div class="layer-4 layer-photo photo-zoom"></div></li>
        <li class="layer" data-depth="0.40"><div class="layer-3 layer-photo photo-zoom"></div></li>
        <li class="layer" data-depth="0.20"><div class="layer-2 layer-photo photo-zoom"></div></li>
        <li class="layer" data-depth="0.00"><div class="layer-1 layer-photo"></div></li>
    </ul>
</template>
<script>
export default {
    name: 'Scene'
}
</script>

